import React from 'react';
import Select from 'react-select';
import { getTheme } from 'utils/SelectTheme';
import './TextSelect.scss';
import DropdownStyles from 'views/components/DropdownStyles';

const TextSelect = ({ icon, label, options, placeholder, onChangeTextSelect, value, isClereable }) => {
  const clearOption = {
    label: 'Clear selection',
    value: null,
  };

  return (
    <div className="TextSelect">
      {icon}
      <p>{label}</p>
      <Select
        classNamePrefix="select"
        placeholder={placeholder}
        value={value ?? undefined}
        onChange={onChangeTextSelect}
        options={isClereable && value ? [clearOption, ...options] : options}
        isSearchable={false}
        theme={getTheme}
        styles={DropdownStyles}
        data-testid={'text-select'}
      />
    </div>
  );
};

export default TextSelect;
