import gql from 'graphql-tag';
import {
  PROJECT_FRAGMENT,
  PROJECT_LIST_FRAGMENT,
  LOCATION_FRAGMENT,
  SERVICE_CONNECTION_FRAGMENT,
  METRO_LABEL_FRAGMENT,
  IMPORTED_PHASE_FRAGMENT,
  PHASE_FRAGMENT,
  TEMPLATE_PROJECT_FRAGMENT,
  COMPANY_FRAGMENT,
  USER_FRAGMENT,
} from './Fragments';

const CREATE_PROJECT = gql`
    mutation CreateProject(
        $name: String!
        $customer: String!
        $segmentId: Long
        $subSegmentId: Long
        $aspId: String
    ) {
        createProject(
            name: $name
            customer: $customer
            segmentId: $segmentId
            subSegmentId: $subSegmentId
            aspId: $aspId
        ) {
            id
            name
            customer {
                id
                name
                segment {
                    id
                    name
                }
                subSegment {
                    id
                    name
                }
            }
            owner {
                ...UserField
            }
            sharedUsers {
                email
            }
            phases {
                id
                name
            }
        }
    }
    ${USER_FRAGMENT}
`;

const UPDATE_PROJECT = gql`
    mutation UpdateProject($param: ProjectInput!) {
        updateProject(param: $param) {
            id
            name
            customer {
                id
                name
                segment {
                    id
                    name
                }
                subSegment {
                    id
                    name
                }
            }
        }
    }
`;

const DUPLICATE_PROJECT = gql`
    mutation DuplicateProject($projectId: Long!) {
        duplicateProject(projectId: $projectId) {
            id
            name
            customer {
                id
                name
                segment {
                    id
                    name
                }
                subSegment {
                    id
                    name
                }
            }
        }
    }
`;

const SHARE_PROJECT = gql`
    mutation ShareProject($projectId: Long!, $emails: EmailSet!) {
        shareProject(projectId: $projectId, emails: $emails) {
            ...ProjectListFields
        }
    }
    ${PROJECT_LIST_FRAGMENT}
`;

const UNSHARE_PROJECT = gql`
    mutation UnshareProject($projectId: Long!, $userId: Long!) {
        unshareProject(projectId: $projectId, userId: $userId) {
            ...ProjectListFields
        }
    }
    ${PROJECT_LIST_FRAGMENT}
`;

const DELETE_PROJECT = gql`
    mutation DeleteProject($id: Long!) {
        deleteProject(projectId: $id)
    }
`;

const ADD_METRO = gql`
    mutation AddProjectMetro(
        $projectId: Long!
        $phaseId: Long!
        $metroId: String!
        $existing: Boolean
    ) {
        addProjectMetro(
            projectId: $projectId
            phaseId: $phaseId
            metroId: $metroId
            existing: $existing
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ENABLE_METAL = gql`
    mutation EnableProjectMetroMetal(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
    ) {
        enableProjectMetroMetal(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DISABLE_METAL = gql`
    mutation DisableProjectMetroMetal(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
    ) {
        disableProjectMetroMetal(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_METAL_TO_METRO = gql`
    mutation AddProjectMetroMetalPlan(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $planId: String!
    ) {
        addProjectMetroMetalPlan(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            planId: $planId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const REMOVE_METAL_FROM_METRO = gql`
    mutation RemoveProjectMetroMetalPlan(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $planId: String!
    ) {
        removeProjectMetroMetalPlan(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            planId: $planId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ENABLE_NETWORK_EDGE = gql`
    mutation EnableProjectMetroNetworkEdge(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $categoryId: String
    ) {
        enableProjectMetroNetworkEdge(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            categoryId: $categoryId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UNDO = gql`
    mutation undo(
        $projectId: Long!
        $phaseId: Long!
    ) {
        undo (
            projectId: $projectId
            phaseId: $phaseId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DISABLE_NETWORK_EDGE = gql`
    mutation DisableProjectMetroNetworkEdge(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $categoryId: String
    ) {
        disableProjectMetroNetworkEdge(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            categoryId: $categoryId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_NETWORK_EDGE_DEVICE_TO_METRO = gql`
    mutation AddProjectMetroNetworkEdgeDevice(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $deviceId: String!
    ) {
        addProjectMetroNetworkEdgeDevice(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            deviceId: $deviceId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const REMOVE_NETWORK_EDGE_DEVICE_FROM_METRO = gql`
    mutation RemoveProjectMetroNetworkEdgeDevice(
        $projectId: Long!
        $phaseId: Long!
        $metroCode: String!
        $deviceId: String!
    ) {
        removeProjectMetroNetworkEdgeDevice(
            projectId: $projectId
            phaseId: $phaseId
            metroCode: $metroCode
            deviceId: $deviceId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_LOCATION = gql`
    mutation AddProjectLocation(
        $projectId: Long!
        $phaseId: Long!
        $name: String
        $type: String!
        $address: AddressInput!
        $coordinates: String!
        $employees: Int
        $notes: String
    ) {
        addProjectLocation(
            projectId: $projectId
            phaseId: $phaseId
            name: $name
            type: $type
            address: $address
            coordinates: $coordinates
            employees: $employees
            notes: $notes
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_LOCATIONS = gql`
    mutation AddProjectLocations(
        $projectId: Long!
        $phaseId: Long!
        $fileReference: String!
        $notes: String
    ) {
        addProjectLocations(
            projectId: $projectId
            phaseId: $phaseId
            fileReference: $fileReference
            notes: $notes
        ) {
            ...ImportedPhaseFields
        }
    }
    ${IMPORTED_PHASE_FRAGMENT}
`;

const UPDATE_LOCATION = gql`
    mutation UpdateProjectLocation(
        $projectId: Long!
        $phaseId: Long!
        $locationId: Long!
        $name: String
        $type: String!
        $address: AddressInput!
        $coordinates: String!
        $employees: Int
        $notes: String
    ) {
        updateProjectLocation(
            projectId: $projectId
            phaseId: $phaseId
            locationId: $locationId
            name: $name
            type: $type
            address: $address
            coordinates: $coordinates
            employees: $employees
            notes: $notes
        ) {
            ...LocationFields
        }
    }
    ${LOCATION_FRAGMENT}
`;

const ADD_LOCATION_GROUP = gql`
    mutation AddProjectLocationGroup(
        $projectId: Long!
        $phaseId: Long!
        $name: String!
        $color: String!
        $locations: [Long!]!
    ) {
        addProjectLocationGroup(
            projectId: $projectId
            phaseId: $phaseId
            name: $name
            color: $color
            locations: $locations
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UPDATE_LOCATION_GROUP = gql`
    mutation UpdateProjectLocationGroup(
        $projectId: Long!
        $phaseId: Long!
        $locationGroupId: Long!
        $name: String
        $color: String
    ) {
        updateProjectLocationGroup(
            projectId: $projectId
            phaseId: $phaseId
            locationGroupId: $locationGroupId
            name: $name
            color: $color
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_LOCATION_GROUP_LOCATIONS = gql`
    mutation AddProjectLocationGroupLocations(
        $projectId: Long!
        $phaseId: Long!
        $locationGroupId: Long!
        $locations: [Long!]!
    ) {
        addProjectLocationGroupLocations(
            projectId: $projectId
            phaseId: $phaseId
            locationGroupId: $locationGroupId
            locations: $locations
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UNGROUP_LOCATION_GROUP_LOCATIONS = gql`
    mutation UngroupProjectLocationGroupLocations(
        $projectId: Long!
        $phaseId: Long!
        $locationGroupId: Long!
        $locations: [Long!]!
    ) {
        ungroupProjectLocationGroupLocations(
            projectId: $projectId
            phaseId: $phaseId
            locationGroupId: $locationGroupId
            locations: $locations
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DELETE_LOCATION_GROUP_LOCATIONS = gql`
    mutation DeleteProjectLocationGroupLocations(
        $projectId: Long!
        $phaseId: Long!
        $locationGroupId: Long!
        $locations: [Long!]!
    ) {
        deleteProjectLocationGroupLocations(
            projectId: $projectId
            phaseId: $phaseId
            locationGroupId: $locationGroupId
            locations: $locations
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UNGROUP_AND_DELETE_LOCATION_GROUP = gql`
    mutation UngroupAndDeleteProjectLocationGroup(
        $projectId: Long!
        $phaseId: Long!
        $locationGroupId: Long!
    ) {
        ungroupAndDeleteProjectLocationGroup(
            projectId: $projectId
            phaseId: $phaseId
            locationGroupId: $locationGroupId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_ACCOUNT_STRATEGY_PLAN = gql`
    mutation AddAccountStrategyPlan($projectId: Long!, $phaseId: Long! $aspId: String!) {
        addProjectAccountStrategyPlan(projectId: $projectId, phaseId: $phaseId, aspId: $aspId) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ACKNOWLEDGE_ACCOUNT_STRATEGY_PLAN_UPDATE = gql`
    mutation AcknowledgeAccountStrategyPlanUpdate(
        $projectId: Long!
        $phaseId: Long!
        $aspId: String!
    ) {
        acknowledgeAccountStrategyPlanUpdate(
            projectId: $projectId
            phaseId: $phaseId
            aspId: $aspId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_SERVICE = gql`
    mutation AddProjectService(
        $projectId: Long!
        $phaseId: Long!
        $serviceId: Long!
        $metroId: String
        $serviceRegionIds: [String!]!
    ) {
        addProjectService(
            projectId: $projectId
            phaseId: $phaseId
            serviceId: $serviceId
            metroId: $metroId
            serviceRegionIds: $serviceRegionIds
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UPDATE_SERVICE = gql`
    mutation UpdateProjectService(
        $projectId: Long!
        $phaseId: Long!
        $serviceId: Long!
        $metroId: String
        $notes: String
        $visible: Boolean
    ) {
        updateProjectService(
            projectId: $projectId
            phaseId: $phaseId
            serviceId: $serviceId
            metroId: $metroId
            notes: $notes
            visible: $visible
        ) {
            ...ServiceConnectionFields
        }
    }
    ${SERVICE_CONNECTION_FRAGMENT}
`;

const ENABLE_SERVICE_REGION = gql`
    mutation EnableServiceRegions(
        $projectId: Long!
        $phaseId: Long!
        $serviceConnectionId: String!
        $serviceRegionIds: [String!]!
    ) {
        enableServiceRegions(
            projectId: $projectId
            phaseId: $phaseId
            serviceConnectionId: $serviceConnectionId
            serviceRegionIds: $serviceRegionIds
        ) {
            ...ServiceConnectionFields
        }
    }
    ${SERVICE_CONNECTION_FRAGMENT}
`;

const DISABLE_SERVICE_REGION = gql`
    mutation DisableServiceRegions(
        $projectId: Long!
        $phaseId: Long!
        $serviceRegionIds: [String!]!
        $serviceConnectionId: String!
    ) {
        disableServiceRegions(
            projectId: $projectId
            phaseId: $phaseId
            serviceConnectionId: $serviceConnectionId
            serviceRegionIds: $serviceRegionIds
        ) {
            ...ServiceConnectionFields
        }
    }
    ${SERVICE_CONNECTION_FRAGMENT}
`;

const UPDATE_METRO = gql`
    mutation UpdateProjectMetro(
        $projectId: Long!
        $phaseId: Long!
        $metroId: String!
        $notes: String
        $serviceOffset: PointInput
        $existing: Boolean
    ) {
        updateProjectMetro(
            projectId: $projectId
            phaseId: $phaseId
            metroId: $metroId
            notes: $notes
            serviceOffset: $serviceOffset
            existing: $existing
        ) {
            id
            name
            region
            notes
            serviceOffset {
                x
                y
            }
            existing
            coordinates {
                latitude
                longitude
            }
            locations {
                id
                name
                type
                notes
                address {
                    label
                    city
                    state
                    postalCode
                    country
                }
                coordinates {
                    latitude
                    longitude
                }
                metro {
                    id
                    name
                }
            }
            services {
                id
                name
                local
                notes
                metro {
                    id
                    name
                }
            }
        }
    }
`;

const REMOVE_METRO = gql`
    mutation RemoveProjectMetro($projectId: Long!, $phaseId: Long!, $metroId: String!) {
        removeProjectMetro(projectId: $projectId, phaseId: $phaseId, metroId: $metroId) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_METRO_LABEL = gql`
    mutation AddProjectMetroLabel(
        $projectId: Long!
        $phaseId: Long!
        $metroId: String!
        $label: LabelInput!
    ) {
        addProjectMetroLabel(
            projectId: $projectId
            phaseId: $phaseId
            metroId: $metroId
            label: $label
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UPDATE_METRO_LABEL = gql`
    mutation UpdateProjectMetroLabel(
        $projectId: Long!
        $phaseId: Long!
        $metroId: String!
        $labelId: Long!
        $label: LabelInput!
    ) {
        updateProjectMetroLabel(
            projectId: $projectId
            phaseId: $phaseId
            metroId: $metroId
            labelId: $labelId
            label: $label
        ) {
            ...MetroLabelFields
        }
    }
    ${METRO_LABEL_FRAGMENT}
`;

const DELETE_METRO_LABEL = gql`
    mutation DeleteProjectMetroLabel(
        $projectId: Long!
        $phaseId: Long!
        $metroId: String!
        $labelId: Long!
    ) {
        deleteProjectMetroLabel(
            projectId: $projectId
            phaseId: $phaseId
            metroId: $metroId
            labelId: $labelId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const ADD_DIRECT_CONNECTION = gql`
    mutation AddDirectConnection(
        $projectId: Long!
        $phaseId: Long!
        $sourceMetroId: String!
        $destinationMetroId: String!
        $label: String!
        $color: String!
    ) {
        addDirectConnection(
            projectId: $projectId
            phaseId: $phaseId
            sourceMetroId: $sourceMetroId
            destinationMetroId: $destinationMetroId
            label: $label
            color: $color
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const UPDATE_DIRECT_CONNECTION = gql`
    mutation UpdateDirectConnection(
        $projectId: Long!
        $phaseId: Long!
        $sourceMetroId: String!
        $destinationMetroId: String!
        $label: String!
        $color: String!
    ) {
        updateDirectConnection(
            projectId: $projectId
            phaseId: $phaseId
            sourceMetroId: $sourceMetroId
            destinationMetroId: $destinationMetroId
            label: $label
            color: $color
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DELETE_DIRECT_CONNECTION = gql`
    mutation DeleteDirectConnection(
        $projectId: Long!
        $phaseId: Long!
        $sourceMetroId: String!
        $destinationMetroId: String!
    ) {
        deleteDirectConnection(
            projectId: $projectId
            phaseId: $phaseId
            sourceMetroId: $sourceMetroId
            destinationMetroId: $destinationMetroId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const MOVE_PROJECT_ITEMS = gql`
    mutation MoveProjectItems(
        $projectId: Long!
        $phaseId: Long!
        $locationIds: [Long!]!
        $locationGroupIds: [Long!]!
        $serviceConnectionIds: [String!]!
        $newMetroId: String
    ) {
        moveProjectItems(
            projectId: $projectId
            phaseId: $phaseId
            locationIds: $locationIds
            locationGroupIds: $locationGroupIds
            serviceConnectionIds: $serviceConnectionIds
            newMetroId: $newMetroId
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DELETE_PROJECT_ITEMS = gql`
    mutation DeleteProjectItems(
        $projectId: Long!
        $phaseId: Long!
        $locationIds: [Long!]!
        $locationGroupIds: [Long!]!
        $serviceConnectionIds: [String!]!
    ) {
        deleteProjectItems(
            projectId: $projectId
            phaseId: $phaseId
            locationIds: $locationIds
            locationGroupIds: $locationGroupIds
            serviceConnectionIds: $serviceConnectionIds
        ) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const RECOMMEND_METROS = gql`
    mutation RecommendMetros($projectId: Long!, $phaseId: Long!) {
        recommendMetros(projectId: $projectId, phaseId: $phaseId) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const DELETE_PROJECT_PHASE = gql`
    mutation DeleteProjectPhase($projectId: Long!, $phaseId: Long!) {
        deleteProjectPhase(projectId: $projectId, phaseId: $phaseId) {
            ...ProjectFields
        }
    }
    ${PROJECT_FRAGMENT}
`;

const UPDATE_PROJECT_PHASE = gql`
    mutation UpdateProjectPhase($projectId: Long!, $phaseId: Long!, $name: String, $order: Int) {
        updateProjectPhase(projectId: $projectId, phaseId: $phaseId, name: $name, order: $order) {
            ...ProjectFields
        }
    }
    ${PROJECT_FRAGMENT}
`;

const ADD_PROJECT_PHASE = gql`
    mutation AddProjectPhase($projectId: Long!, $name: String, $order: Int) {
        addProjectPhase(projectId: $projectId, name: $name, order: $order) {
            ...ProjectFields
        }
    }
    ${PROJECT_FRAGMENT}
`;

const DUPLICATE_PROJECT_PHASE = gql`
    mutation DuplicateProjectPhase($projectId: Long!, $phaseId: Long!) {
        duplicatePhase(projectId: $projectId, phaseId: $phaseId) {
            ...ProjectFields
        }
    }
    ${PROJECT_FRAGMENT}
`;

const SIGN_OUT = gql`
    mutation SignOut {
        signOut
    }
`;

const CREATE_TEMPLATE = gql`
    mutation CreateTemplate(
        $name: String!,
        $categories: [String]!
    ) {
        createTemplate(
            name: $name,
            categories: $categories
        ) {
            ...TemplateProjectFields
        }
    }
    ${TEMPLATE_PROJECT_FRAGMENT}
`;

const UPDATE_TEMPLATE = gql`
    mutation UpdateTemplate($param: TemplateInput!) {
        updateTemplate(param: $param) {
            ...TemplateProjectFields
        }
    }
    ${TEMPLATE_PROJECT_FRAGMENT}
`;

const UPGRADE_TEMPLATE = gql`
    mutation UpgradeTemplate($templateId: Long!) {
        upgradeTemplate(templateId: $templateId) {
            id
            updateAvailable
        }
    }
`;

const CREATE_PROJECT_FROM_TEMPLATE = gql`
    mutation CreateProjectFromTemplate(
        $templateId: Long!
        $name: String!
        $customer: String!
        $segmentId: Long
        $subSegmentId: Long
        $aspId: String
    ) {
        createProjectFromTemplate(
            templateId: $templateId
            name: $name
            customer: $customer
            segmentId: $segmentId
            subSegmentId: $subSegmentId
            aspId: $aspId
        ) {
            ...ProjectFields
        }
    }
    ${PROJECT_FRAGMENT}
`;

const USER_SAVE_TEMPLATE = gql`
    mutation SaveTemplate ($templateId: Long!)  {
        saveTemplate(templateId: $templateId) {
            id
            isSaved
            timesSaved
        }
    }
`;

const USER_UNSAVE_TEMPLATE = gql`
    mutation UnsaveTemplate ($templateId: Long!)  {
        unsaveTemplate(templateId: $templateId) {
            id
            isSaved
            timesSaved
        }
    }
`;

const CREATE_TEMPLATE_FROM_PROJECT = gql`
    mutation CreateTemplateFromProject(
        $projectId: Long!
        $name: String!,
        $categories: [String]!
    ) {
        createTemplateFromProject(
            projectId: $projectId,
            name: $name,
            categories: $categories
        ) {
            ...TemplateProjectFields
        }
    }
    ${TEMPLATE_PROJECT_FRAGMENT}
`;

const DELETE_TEMPLATE = gql`
    mutation DeleteTemplate($templateId: Long!) {
        deleteTemplate(templateId: $templateId) {
            id
        }
    }
`;

const CREATE_COMPANY = gql`
    mutation CreateCompany(
        $name: String!,
        $isDSPAllowed: Boolean!,
        $domains:[AllowedDomainInput!]!,
    ) {
        createCompany(
            name:$name,
            isDSPAllowed:$isDSPAllowed,
            domains:$domains,
        ) {
            ...CompanyField
        }
    }
    ${COMPANY_FRAGMENT}
`;

const UPDATE_COMPANY = gql`
    mutation UpdateCompany(
        $companyId: Long!,
        $name: String!,
        $isDSPAllowed: Boolean!,
        $domains:[AllowedDomainInput!]!,
    ) {
        updateCompany(
            companyId:$companyId,
            name:$name,
            isDSPAllowed:$isDSPAllowed,
            domains:$domains,
        ) {
            ...CompanyField
        }
    }
    ${COMPANY_FRAGMENT}
`;

const DELETE_COMPANY = gql`
mutation DeleteCompany(
    $companyId: Long!,
    $sendEmail: Boolean!,
) {
    removeCompany(
        companyId:$companyId,
        sendEmail:$sendEmail,
    ) {
        id
    }
}
`;

const RESTORE_COMPANY = gql`
    mutation CreateCompany($companyId: Long!, $sendEmail: Boolean!,) {
        restoreCompany (
            companyId:$companyId,
            sendEmail:$sendEmail,
        ) {
            id
        }
    }
`;

const ADD_USER_TO_COMPANY = gql`
    mutation AddUserToCompany($companyId: Long!, $userEmail: [Email!]!, $userRole: Int!) {
        addUserToCompany (
            companyId:$companyId,
            userEmail:$userEmail,
            userRole: $userRole,
        ) {
            id
        }
    }
`;

const DELETE_USER = gql`
mutation DeleteUser(
    $userId: Long!,
) {
    deleteUser(
        userId:$userId,
    ) {
        id
    }
}
`;

const UPDATE_USER = gql`
    mutation UpdateUserRole(
        $userId: Long!,
        , $role: Int!
    ) {
        updateUserRole(
            userId:$userId,
            role:$role,
        ) {
            id
        }
    }
`;

export default {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DUPLICATE_PROJECT,
  DUPLICATE_PROJECT_PHASE,
  SHARE_PROJECT,
  UNSHARE_PROJECT,
  DELETE_PROJECT,
  ADD_METRO,
  UPDATE_METRO,
  REMOVE_METRO,
  ENABLE_METAL,
  DISABLE_METAL,
  ADD_METAL_TO_METRO,
  REMOVE_METAL_FROM_METRO,
  ENABLE_NETWORK_EDGE,
  DISABLE_NETWORK_EDGE,
  ADD_NETWORK_EDGE_DEVICE_TO_METRO,
  REMOVE_NETWORK_EDGE_DEVICE_FROM_METRO,
  ADD_METRO_LABEL,
  UPDATE_METRO_LABEL,
  DELETE_METRO_LABEL,
  ADD_DIRECT_CONNECTION,
  UPDATE_DIRECT_CONNECTION,
  DELETE_DIRECT_CONNECTION,
  ADD_LOCATION,
  ADD_LOCATIONS,
  UPDATE_LOCATION,
  ADD_LOCATION_GROUP,
  ADD_LOCATION_GROUP_LOCATIONS,
  UNGROUP_LOCATION_GROUP_LOCATIONS,
  DELETE_LOCATION_GROUP_LOCATIONS,
  UPDATE_LOCATION_GROUP,
  UNGROUP_AND_DELETE_LOCATION_GROUP,
  ADD_ACCOUNT_STRATEGY_PLAN,
  ACKNOWLEDGE_ACCOUNT_STRATEGY_PLAN_UPDATE,
  ADD_SERVICE,
  UPDATE_SERVICE,
  ENABLE_SERVICE_REGION,
  DISABLE_SERVICE_REGION,
  MOVE_PROJECT_ITEMS,
  DELETE_PROJECT_ITEMS,
  RECOMMEND_METROS,
  DELETE_PROJECT_PHASE,
  UPDATE_PROJECT_PHASE,
  ADD_PROJECT_PHASE,
  SIGN_OUT,
  UNDO,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  UPGRADE_TEMPLATE,
  CREATE_PROJECT_FROM_TEMPLATE,
  USER_SAVE_TEMPLATE,
  USER_UNSAVE_TEMPLATE,
  CREATE_TEMPLATE_FROM_PROJECT,
  DELETE_TEMPLATE,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  RESTORE_COMPANY,
  ADD_USER_TO_COMPANY,
  DELETE_USER,
  UPDATE_USER,
};
