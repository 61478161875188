// let phases = [
//   {
//     id: '1',
//     name: 'Base setup',
//     order: 1,
//     services: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [],
//     __typename: 'Phase',
//   },
//   {
//     id: '2',
//     name: 'Network refinements',
//     order: 2,
//     services: [],
//     sharedUsers: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [
//       {
//         id: 'AMER_DENVER',
//         name: 'Denver',
//         region: 'AMER',
//         code: 'DE',
//         status: 'OPEN',
//         notes: null,
//         serviceOffset: {
//           x: 0,
//           y: 0,
//           __typename: 'Point',
//         },
//         existing: false,
//         coordinates: {
//           latitude: 39.587392,
//           longitude: -104.876307,
//           __typename: 'GeoCoordinates',
//         },
//         locations: [],
//         groups: [],
//         services: [],
//         labels: [],
//         metalEnabled: false,
//         metalPlans: [],
//         networkEdgeEnabled: false,
//         networkEdgeCategories: [],
//         __typename: 'ProjectMetro',
//       },
//     ],
//     __typename: 'Phase',
//   },
//   {
//     id: '3',
//     name: 'Deployment',
//     order: 3,
//     services: [],
//     sharedUsers: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [],
//     __typename: 'Phase',
//   },
// ];

// let mockProject = {
//   id: 123,
//   name: 'Project Name',
//   customer: {
//     name: 'Customer Name',
//   },
//   owner: {
//     email: 'joaquin.zapata@nan-labs.com',
//     id: '493880204138119169',
//     __typename: 'User',
//   },
//   sharedUsers: [
//     {
//       email: 'example@example.com',
//       id: '123',
//       __typename: 'User',
//     },
//     {
//       email: 'email@example.com',
//       id: '321',
//       __typename: 'User',
//     },
//     {
//       email: 'name@company.com',
//       id: '456',
//       __typename: 'User',
//     },
//   ],
//   phases,
//   selectedPhaseId: '1',
//   __typename: 'Project',
// };

// let templates = [
//   {
//     id: 'template1',
//     name: 'Template 1',
//     description:
//       'Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore nostrud id fugiat nostrud irure ex duis ea quis id quis ure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore nost',
//     published: true,
//     timesSaved: 5,
//     timesUsed: 20,
//     tags: ['EMEA', 'Enterprice', 'Equinix Metal', 'Global', 'AMER'],
//     createdOn: 1649855298,
//     lastUpdated: 1649855299,
//     version: 1,
//     owner: {
//       email: 'joaquin.zapata@nan-labs.com',
//       id: '493880204138119169',
//       __typename: 'User',
//     },
//     thumbnail:'',
//     project: mockProject,
//     __typename: 'Template',
//   },
//   {
//     id: 'template2',
//     name: 'Template 2',
//     description: 'Another good template',
//     published: true,
//     timesSaved: 8,
//     timesUsed: 25,
//     tags: ['EMEA', 'Enterprice'],
//     createdOn: 1649855298,
//     lastUpdated: 1649855299,
//     version: 1,
//     owner: {
//       email: 'joaquin.zapata@nan-labs.com',
//       id: '493880204138119169',
//       __typename: 'User',
//     },
//     thumbnail:'',
//     project: mockProject,
//     __typename: 'Template',
//   },
// ];

// const createProjectFromTemplateResolver = () => {
//   return mockProject;
// };

// const updateTemplateResolver = (param) => {
//   console.log({ param });
// };

// const togglePublicTemplateResolver = (templateId) => {
//   console.log({ templateId });
//   const template = templates.find(({ id }) => id === templateId);
//   template.published = !template.published;

//   return template;
// };

let companies = [
  {
    companyId: '001',
    name: 'Company name 1',
    owner: 'company@owner.com',
    withDSP: false,
    removalDate: null,
    admins: ['company@admin.com'],
    domains: [],
    members: [
      {
        userId: '001',
        email: 'workbench@admin.com',
        isDeleted: false,
        acceptedTC: true,
        companyId: '001',
        role: 1,
        __typename: 'User',
      },
      {
        userId: '002',
        email: 'company@owner.com',
        isDeleted: false,
        acceptedTC: true,
        companyId: '001',
        role: 2,
        __typename: 'User',
      },
      {
        userId: '003',
        email: 'company@admin.com',
        isDeleted: false,
        acceptedTC: false,
        companyId: '001',
        role: 3,
        __typename: 'User',
      },
      {
        userId: '004',
        email: 'company@member.com',
        isDeleted: true,
        acceptedTC: true,
        companyId: '001',
        role: 4,
        __typename: 'User',
      },
    ],
    __typename: 'Company',
  },
  {
    companyId: '002',
    name: 'Company name 2',
    owner: 'company2@owner.com',
    withDSP: true,
    removalDate: 'Wed Aug 16 2023 11:54:00 GMT-0300 (Argentina Standard Time)',
    admins: [],
    domains: [],
    members: [
      {
        userId: '005',
        email: 'company2@owner.com',
        isDeleted: false,
        acceptedTC: true,
        companyId: '002',
        role: 2,
        __typename: 'User',
      },
    ],
    __typename: 'Company',
  },
];

const getCompaniesResolver = () => {
  return companies;
};

const getCompanyResolver = (companyId) => {
  return [companies.find((company) => company.companyId === companyId)];
};

const createCompanyResolver = (params) => {
  companies.push({ ...params });
  console.log({ create: companies });
  return companies;
};

const deleteCompanyResolver = (companyId) => {
  companies = companies.map((company) => {
    if (company.companyId === companyId) {
      return { ...company, removalDate: new Date() };
    }
    return company;
  });
  console.log({ delete: companies });
  return companyId;
};

const restoreCompanyResolver = (companyId) => {
  companies = companies.map((company) => {
    if (company.companyId === companyId) {
      return { ...company, removalDate: null };
    }
    return company;
  });
  console.log({ restore: companies });
  return companyId;
};

const resolvers = {
  Query: {
    companies: () => getCompaniesResolver(),
    company: (_, { companyId }) => getCompanyResolver(companyId),
  },
  Mutation: {
    createCompany: (_, params) => createCompanyResolver(params),
    deleteCompany: (_, { companyId }) => deleteCompanyResolver(companyId),
    restoreCompany: (_, { companyId }) => restoreCompanyResolver(companyId),
  },
};

export default resolvers;
