import React, { useState } from 'react';
import InfoIcon from 'views/components/icons/Info';
import DatasourcesModalView from 'views/components/DatasourcesModalView';

const About = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);
  return (
    <>
      <a href="#about" onClick={openModal} className="user_linked-icon-link user_linked-icon-link--about" data-testid={'about'}>
        <InfoIcon height={20} width={20} style={{ marginTop: '4px' }} />
        <span>Data sources</span>
      </a>
      {modalOpen && <DatasourcesModalView onClose={closeModal} />}
    </>
  );
};

export default About;
