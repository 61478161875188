import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { getIcon } from './icons';
import Clusterer from './Clusterer';
import ContextMenu from './ContextMenu';
import ServiceMarker from './ServiceMarker';
import PhaseLocation from './PhaseLocation';
import CustomLabel from './CustomLabel';
import withRemoveMetro from '../hocs/withRemoveMetro';
import ProductEvents from 'analytics/ProductEvents';
import { MetroStatus } from 'model/Metro';
import Circle from './Circle';
import msToMeters from 'utils/msToMeters';
import { dismissAllToasts } from 'utils/Toast';

class ActiveMetro extends React.Component {
  state = {};

  onDelete = async () => {
    const { removeMetro, projectId, phaseId, metro, selected, onClose } = this.props;
    this.setState({ contextMenu: false });
    if (selected?.id === metro.id || metro.services.includes(selected)) {
      onClose();
    }

    await removeMetro(projectId, phaseId, metro.id);
    dismissAllToasts();

    ProductEvents.metroRemoved(metro.name);
  };

  _onContextMenu = () => {
    const { isEditing } = this.props;
    if (!isEditing) return;
    this.setState({ contextMenu: true });
  };

  render() {
    const {
      projectId,
      phaseId,
      metro,
      selected,
      onSelect,
      loading,
      iconSize = 1,
      layers,
      ringLatency,
      ringColor,
      onMetroPanelTabChange,
      onClose,
      isEditing,
    } = this.props;
    const locations = metro.locations;
    const latencyRingRadius = msToMeters(ringLatency, metro.region);
    const services = metro.services.filter((s) => s.visible);
    const tooltipOffset = [0, iconSize < 0.7 ? -12 : -25];

    return (
      <React.Fragment>
        <Clusterer
          projectId={projectId}
          phaseId={phaseId}
          connectToPosition={metro.coordinates.toLeaflet()}
          iconSize={iconSize}
          customPaneZIndex={1001}
          hideCustomerLines={!layers.customerLocationLines}
          metro={metro}>
          {layers.activeMetros && (
            <Marker
              weight={100000}
              metro={metro}
              position={metro.coordinates.toLeaflet()}
              icon={getIcon('metro', iconSize, selected && metro.id.equals(selected.id), true, metro.existing, metro.status === MetroStatus.UNDER_ACQUISITION)}
              onClick={() => onSelect('metro', metro)}
              onContextMenu={this._onContextMenu}
              opacity={loading ? 0.8 : 1}
              existing={metro.existing}
              status={metro.status}
              zIndexOffset={500}>
              <Tooltip key={`${metro.code}-${iconSize}-AM`} pane={'tooltipPane'} direction="top" offset={tooltipOffset}>
                {metro.name}
              </Tooltip>
              {this.state.contextMenu && (
                <ContextMenu onClose={() => this.setState({ contextMenu: false })}>
                  <button onClick={this.onDelete}>Remove Metro</button>
                </ContextMenu>
              )}
            </Marker>
          )}
          {layers.customerLocations &&
            locations.map((location) => (
              <PhaseLocation
                key={location.id}
                projectId={projectId}
                phaseId={phaseId}
                weight={1}
                location={location}
                metro={metro}
                selected={selected}
                onSelect={onSelect}
                iconSize={iconSize}
                onClose={onClose}
                isEditing={isEditing}
              />
            ))}
        </Clusterer>
        {layers.services && (
          <ServiceMarker
            key={`${phaseId}-${metro.id}`}
            projectId={projectId}
            phaseId={phaseId}
            metro={metro}
            position={metro.coordinates.toLeaflet()}
            services={services}
            iconSize={iconSize}
            serviceOffset={metro.serviceOffset}
            selected={selected}
            onSelect={onSelect}
            onMetroPanelTabChange={onMetroPanelTabChange}
            onClose={onClose}
            isEditing={isEditing}
          />
        )}
        {layers.activeMetros && (
          <>
            {layers.labels &&
              metro.labels.map((label, index) => (
                <CustomLabel
                  key={`${phaseId}-${label.id}`}
                  projectId={projectId}
                  phaseId={phaseId}
                  metro={metro}
                  label={label}
                  index={index}
                  position={metro.coordinates.toLeaflet()}
                  iconSize={iconSize}
                />
              ))}
            {layers.latencyRings && <Circle center={metro.coordinates.toLeaflet()} radius={latencyRingRadius} color={ringColor} interactive={false} />}
          </>
        )}
      </React.Fragment>
    );
  }
}

export default withRemoveMetro(ActiveMetro);
