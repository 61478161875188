export const connectionTypes = [
  {
    label: 'Topology',
    value: 'topological',
  },
  {
    label: 'Mesh',
    value: 'logical',
  },
];

export const metroOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Existing Only',
    value: 'existing',
  },
  {
    label: 'Recommended Only',
    value: 'recommended',
  },
];

export const maxRingLatency = 99;
export const minRingLatency = 0;

export const ringColorOptions = [
  {
    label: 'Red',
    value: 'red',
  },
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Yellow',
    value: 'yellow',
  },
  {
    label: 'Green',
    value: 'green',
  },
  {
    label: 'Blue',
    value: 'blue',
  },
  {
    label: 'Purple',
    value: 'purple',
  },
];

export const availableRegionsOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Enabled Services Only',
    value: 'enabledServicesOnly',
  },
];
